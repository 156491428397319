// .spinner-wrap {
//     position: fixed;
//     background-color: rgba(0, 0, 0, 0.5);
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     z-index: 1111111111;

//     .spinner {
//         width: 100px;
//         height: 100px;
//         position: fixed;
//         margin: 0 auto;
//         left: 0;
//         right: 0;
//         top: 50%;
//         bottom: 0;
//         -webkit-transform: translateY(-50%);
//         -ms-transform: translateY(-50%);
//         -moz-transform: translateY(-50%);
//         -o-transform: translateY(-50%);
//         transform: translateY(-50%);

//         .double-bounce1,
//         .double-bounce2 {
//             width: 100%;
//             height: 100%;
//             border-radius: 50%;
//             background-color: #ffffff;
//             opacity: 0.6;
//             position: absolute;
//             top: 0;
//             left: 0;
//             -webkit-animation: sk-bounce 2s infinite ease-in-out;
//             animation: sk-bounce 2s infinite ease-in-out;
//         }

//         .double-bounce2 {
//             -webkit-animation-delay: -1s;
//             animation-delay: -1s;
//         }
//     }
// }

// @-webkit-keyframes sk-bounce {
//     0%,
//     100% {
//         -webkit-transform: scale(0);
//     }

//     50% {
//         -webkit-transform: scale(1);
//     }
// }

// @keyframes sk-bounce {
//     0%,
//     100% {
//         transform: scale(0);
//         -webkit-transform: scale(0);
//     }

//     50% {
//         transform: scale(1);
//         -webkit-transform: scale(1);
//     }
// }

.leap-frog {
  --uib-size: 40px;
  --uib-speed: 2s;
  --uib-color: rgb(37, 37, 149);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: var(--uib-size);
}

.leap-frog__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.leap-frog__dot::before {
  content: "";
  display: block;
  height: calc(var(--uib-size) * 0.22);
  width: calc(var(--uib-size) * 0.22);
  border-radius: 50%;
  background-color: var(--uib-color);
  will-change: transform;
}

.leap-frog__dot:nth-child(1) {
  animation: leapFrog var(--uib-speed) ease infinite;
}

.leap-frog__dot:nth-child(2) {
  transform: translateX(calc(var(--uib-size) * 0.4));
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -1.5)
    infinite;
}

.leap-frog__dot:nth-child(3) {
  transform: translateX(calc(var(--uib-size) * 0.8)) rotate(0deg);
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -3) infinite;
}

@keyframes leapFrog {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  33.333% {
    transform: translateX(0) rotate(180deg);
  }

  66.666% {
    transform: translateX(calc(var(--uib-size) * -0.4)) rotate(180deg);
  }

  99.999% {
    transform: translateX(calc(var(--uib-size) * -0.8)) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}
